<template>
  <div :id="chartId">
    <a-empty
      v-if="!chartData"
      :description="false"
      :style="{ height: height + 'px', lineHeight: height + 'px' }"
    ></a-empty>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import { chartColors } from '@/utils/const'
import { getIntegerTicks } from '@/utils'

const chart = {}

export default {
  name: 'DualAxisChart',
  props: {
    chartData: {
      type: Array
    },
    chartId: {
      type: String,
      required: true
    },
    colors: {
      type: [Array, Function],
      default: () => chartColors
    },
    height: {
      type: Number,
      default: 200
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].scale({
      alert: {
        min: 0,
        nice: true,
        tickMethod: getIntegerTicks
      },
      source: {
        min: 0,
        nice: true,
        tickMethod: getIntegerTicks
      }
    })
    chart[this.chartId].legend({
      custom: true,
      items: [
        {
          value: 'source',
          name: '资源',
          marker: { symbol: 'square', style: { fill: '#1890ff', r: 5 } }
        },
        {
          value: 'alert',
          name: '告警',
          marker: {
            symbol: 'hyphen',
            style: { stroke: '#fdae6b', r: 5, lineWidth: 2.5 }
          }
        }
      ]
    })
    chart[this.chartId].axis('alert', {
      grid: null,
      title: {
        style: {
          fill: '#fdae6b'
        },
        text: '告警总数'
      },
      label: {
        style: {
          fill: '#fdae6b'
        }
      }
    })
    chart[this.chartId].axis('source', {
      title: {
        text: '资源总数'
      }
    })
    chart[this.chartId].tooltip({
      shared: true,
      customItems: item => {
        return [
          { ...item[0], name: '资源', value: item[0].value },
          { ...item[1], name: '告警', value: item[1].value }
        ]
      }
    })
    chart[this.chartId].interval().size(20).position('name*source').color('#1890ff').label('source', {
      style: {
        fill: '#1890ff'
      },
      position: 'top',
      offsetY: 12,
      content: (data) => {
        if (data.source) { return data.source }
      }
    })
    chart[this.chartId].appendPadding = [6, 4, 0, 4]
    chart[this.chartId]
      .line()
      .position('name*alert')
      .color('#fdae6b')
      .size(2.5)
      .shape('smooth')
    chart[this.chartId]
      .point()
      .position('name*alert')
      .color('#fdae6b')
      .size(3)
      .shape('circle')
      .label('alert', {
        style: {
          fill: '#fdae6b'
        },
        offsetY: 2,
        content: (data) => {
          if (data.alert) { return data.alert }
        }
      })
    chart[this.chartId].interaction('active-region')
    chart[this.chartId].removeInteraction('legend-filter')
    this.render()
  },
  methods: {
    render () {
      chart[this.chartId].changeData(this.chartData)
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>
