<template>
  <div class="source-report">
    <a-spin :spinning="spinning" style="min-height: 400px">
      <a-row :gutter="16" id="pdfDom">
        <header
          :style="{
            fontSize: '24px',
            fontWeight: 600,
            color: '#096dd9',
            lineHeight: '42px',
            textAlign: 'center',
            paddingTop: '40px'
          }"
        >
          {{ detail.name }}
        </header>
        <div v-if="detail.name" style="text-align: center; font-size: 14px">
          统计周期：{{
            `${detail.datetime_from || '-'} ~  ${detail.datetime_to || '-'}`
          }}
        </div>
        <div style="margin-top: 24px" v-if="detail.statistic['overview'] && detail.statistic['overview'].count">
          <!-- 基本概况 -->
          <div
            id="overview"
            class="pdf-split-flag small-module-title"
          >
            <a-icon type="profile"></a-icon>
            <span> 基本概况</span>
          </div>
          <a-row :gutter="16">
            <a-col :md="24" :xl="7" style="margin-bottom: 16px">
              <count-card
                title="资源总数"
                iconType="hdd"
                :value="detail.statistic['overview'].count"
              ></count-card>
            </a-col>
            <a-col :md="24" :xl="7" style="margin-bottom: 16px">
              <count-card
                title="告警总数"
                iconType="alert"
                :value="detail.statistic['overview']['alert'].count"
              ></count-card>
            </a-col>
            <a-col :md="24" :xl="10" style="margin-bottom: 16px">
              <count-card
                title="告警时长"
                iconType="hourglass"
                :value="
                  humanizeTime(detail.statistic['overview']['alert'].duration)
                "
              ></count-card>
            </a-col>

            <a-col :sm="24" :lg="detail.statistic['overview']['alert'].count ? 12 : 24" style="margin-bottom: 16px">
              <a-card
                size="small"
                class="pdf-split-flag"
                title="数量统计"
                :bodyStyle="bodyStyle"
                :headStyle="headStyle"
              >
                <dual-axis-chart
                  :chart-data="
                    getOverviewData(
                      detail.statistic['overview']['count_of_source_type'].filter(item => filterKeys.findIndex(v => item.name === v) === -1),
                      detail.statistic['overview']['alert'][
                        'count_of_source_type'
                      ].filter(item => filterKeys.findIndex(v => item.name === v) === -1)
                    )
                  "
                  chart-id="overview-source-chart"
                  :height="260"
                ></dual-axis-chart>
              </a-card>
            </a-col>
            <a-col :sm="24" :lg="12" v-if="detail.statistic['overview']['alert'].count" style="margin-bottom: 16px">
              <a-card
                class="pdf-split-flag"
                size="small"
                title="时效统计"
                :bodyStyle="bodyStyle"
                :headStyle="headStyle"
              >
                <bar-chart
                  :chart-data="
                    formatData(
                      detail.statistic['overview']['alert'][
                        'duration_of_source_type'
                      ].filter(item => filterKeys.findIndex(v => item.name === v) === -1)
                      .map(item => {
                        return {
                          name: $t(`source_type.${item.name}`),
                          value: item.value
                        }
                      })
                    )
                  "
                  :height="260"
                  :chart-id="`overview-duration-chart`"
                  :colors="chartColors"
                  format="time"
                  :transpose="true"
                  :legend="false"
                  :showLabel="true"
                ></bar-chart>
              </a-card>
            </a-col>
            <a-col :span="24" v-if="detail.statistic['overview']['alert'].count" style="margin-bottom: 16px">
              <a-card
                class="pdf-split-flag"
                size="small"
                title="告警分布统计"
                :bodyStyle="bodyStyle"
                :headStyle="headStyle"
                v-if="detail.statistic['overview']['alert']"
              >
                <linkage-chart
                  :pie-chart-data="
                    detail.statistic['overview']['alert'][
                      'count_of_source_type'
                    ].filter(item => filterKeys.findIndex(v => item.name === v) === -1)
                    .map(item => {
                      return {
                        name: $t(`source_type.${item.name}`),
                        value: item.value
                      }
                    })
                  "
                  :line-chart-data="
                    detail.statistic['overview']['alert'][
                      'trend_of_source_type'
                    ].filter(item => filterKeys.findIndex(v => item.name === v) === -1)
                    .map(item => {
                      return {
                        date: item.date,
                        name: $t(`source_type.${item.name}`),
                        value: item.value
                      }
                    })
                  "
                  :chart-id="`source-type-of-linkage-chart`"
                  :height="260"
                ></linkage-chart>
              </a-card>
            </a-col>
          </a-row>

          <!-- 网络设备 -->
          <div
            id="network_device"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['network_device']"
          >
            <a-icon type="profile"></a-icon>
            <span> 网络设备</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['network_device'] && detail.statistic['network_device'].count"
            source-type="network_device"
            :detail="detail.statistic['network_device']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['network_device'] && !detail.statistic['network_device'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 操作系统 -->
          <div
            id="os"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['os']"
          >
            <a-icon type="profile"></a-icon>
            <span> 操作系统</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['os'] && detail.statistic['os'].count"
            source-type="os"
            :detail="detail.statistic['os']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['os'] && !detail.statistic['os'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 物理机 -->
          <div
            id="server"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['server']"
          >
            <a-icon type="profile"></a-icon>
            <span> 物理机</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['server'] && detail.statistic['server'].count"
            source-type="server"
            :detail="detail.statistic['server']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['server'] && !detail.statistic['server'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 存储 -->
          <div
            id="storage"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['storage']"
          >
            <a-icon type="profile"></a-icon>
            <span> 存储</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['storage'] && detail.statistic['storage'].count"
            source-type="storage"
            :detail="detail.statistic['storage']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['storage'] && !detail.statistic['storage'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 中间件 -->
          <div
            id="middleware"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['middleware']"
          >
            <a-icon type="profile"></a-icon>
            <span> 中间件</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['middleware'] && detail.statistic['middleware'].count"
            source-type="middleware"
            :detail="detail.statistic['middleware']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['middleware'] && !detail.statistic['middleware'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 数据库 -->
          <div
            id="database"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['database']"
          >
            <a-icon type="profile"></a-icon>
            <span> 数据库</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['database'] && detail.statistic['database'].count"
            source-type="database"
            :detail="detail.statistic['database']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['database'] && !detail.statistic['database'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 域名 -->
          <div
            id="domain"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['domain']"
          >
            <a-icon type="profile"></a-icon>
            <span> 域名</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['domain'] && detail.statistic['domain'].count"
            source-type="domain"
            :detail="detail.statistic['domain']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['domain'] && !detail.statistic['domain'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- ssl -->
          <div
            id="ssl_certificate"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['ssl_certificate']"
          >
            <a-icon type="profile"></a-icon>
            <span> SSL 证书</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['ssl_certificate'] && detail.statistic['ssl_certificate'].count"
            source-type="ssl_certificate"
            :detail="detail.statistic['ssl_certificate']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['ssl_certificate'] && !detail.statistic['ssl_certificate'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 虚拟化 -->
          <div
            id="hypervisor"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['hypervisor']"
          >
            <a-icon type="profile"></a-icon>
            <span> 虚拟化</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['hypervisor'] && detail.statistic['hypervisor'].count"
            source-type="hypervisor"
            :detail="detail.statistic['hypervisor']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['hypervisor'] && !detail.statistic['hypervisor'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>
        </div>
        <div v-else style="height: 400px; position: relative; overflow: hidden;">
          <empty-component
            :imgUrl="require('@/assets/images/info_empty.png')"
            :body-style="{
              height: '220px',
              marginTop: '70px'
            }"
            :description-style="{
              marginTop: '-12px',
              marginRight: '8px',
              color: 'rgba(0,0,0,.55)'
            }"
            description="当前周期内暂无资源"
          >
            <a-button slot="customContent" type="primary">创建新报表</a-button>
          </empty-component>
        </div>
      </a-row>
      <div v-if="detail.statistic['overview'] && detail.statistic['overview'].count" class="tool-box"
        :style="{
          right: !displayAnchor ? '-104px' : 0
        }"
      >
        <div style="position: absolute; left: -21px; top: 24px; background: #ffffffee;  border-radius: 6px 0 0 6px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;">
          <a-button type="link" @click="displayAnchor = !displayAnchor" style="padding: 3px;">
            <a-icon v-if="displayAnchor"  type="double-right" />
            <a-icon v-else type="double-left" />
          </a-button>
        </div>
        <a-button
          @click="exportPdf"
          type="primary"
          style="margin: 16px 0 -6px 16px;"
        >
          导出
        </a-button>
        <a-anchor
          :affix="false"
          :offsetTop="80"
          :wrapperStyle="{ background: 'transparent', marginTop: '24px' }"
        >
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'overview') !== -1"
            href="#overview"
            title="基本概况"
          />
          <a-anchor-link
            v-if="
              anchorData.findIndex(item => item === 'network_device') !== -1
            "
            href="#network_device"
            title="网络设备"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'os') !== -1"
            href="#os"
            title="操作系统"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'server') !== -1"
            href="#server"
            title="物理机"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'storage') !== -1"
            href="#storage"
            title="存储"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'middleware') !== -1"
            href="#middleware"
            title="中间件"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'database') !== -1"
            href="#database"
            title="数据库"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'domain') !== -1"
            href="#domain"
            title="域名"
          />
          <a-anchor-link
            v-if="
              anchorData.findIndex(item => item === 'ssl_certificate') !== -1
            "
            href="#ssl_certificate"
            title="SSL 证书"
          />
          <a-anchor-link
            v-if="anchorData.findIndex(item => item === 'hypervisor') !== -1"
            href="#hypervisor"
            title="虚拟化"
          />
        </a-anchor>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { getSourceReport } from '@/api/report'
import { severityColor, humanizeTime } from '@/utils'
import { chartColors } from '@/utils/const'
import SourceStatisticsModule from '@/components/report/SourceStatisticsModule.vue'
import CountCard from './modules/CountCard'
import BarChart from '@/components/chart/BarChart'
import DualAxisChart from '@/components/chart/DualAxisChart'
import LinkageChart from '@/components/chart/LinkageChart'
import PdfLoader from '@/utils/htmlToPdf'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'SourceReportDetail',
  components: {
    BarChart,
    DualAxisChart,
    LinkageChart,
    CountCard,
    EmptyComponent,
    SourceStatisticsModule
  },
  data () {
    return {
      detail: {
        id: this.$route.params.id,
        name: '',
        statistic: {}
      },
      displayAnchor: true,
      spinning: true,
      chartColors: chartColors,
      anchorData: [],
      tableScroll: { y: 215.5 },
      filterKeys: ['site', 'application'],
      headStyle: { background: '#fff' },
      bodyStyle: {
        overflow: 'hidden',
        background: '#fff',
        borderRadius: '8px'
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    humanizeTime,
    fetch () {
      this.spinning = true
      getSourceReport(this.detail.id)
        .then(res => {
          this.detail = res.data
          this.anchorData = Object.keys(this.detail.statistic)
        })
        .finally(() => {
          this.spinning = false
        })
    },
    getOverviewData (source, alert) {
      return source.map(item => {
        return {
          name: this.$t(`source_type.${item.name}`),
          source: item.value,
          alert: alert.find(e => e.name === item.name)
            ? alert.find(e => e.name === item.name).value
            : 0
        }
      })
    },
    colors (v) {
      return severityColor(v)
    },
    exportPdf () {
      this.spinning = true
      this.tableScroll = {}
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.tableScroll = { y: 215.5 }
          this.spinning = false
        })
      })
    },
    formatData (arr) {
      // 排序
      arr.sort((a, b) => a.value - b.value)
      return arr
    }
  }
}
</script>

<style lang="less">
.source-report {
  background: #fff;
  padding: 0 28px 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  position: relative;

  .ant-card {
    box-shadow: none !important;
  }

  .simple-number {
    text-align: center;
    font-size: 56px;
    line-height: 148px;
  }

  // 模块标题
  .small-module-title {
    height: 35px;
    background: #e4f1ff;
    border-radius: 8px;
    color: #096dd9;
    padding: 0 16px;
    margin-bottom: 16px;
    margin-top: 24px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  // 流量TOP
  #alert-info {
    .alert-info-item {
      height: 32px;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      padding: 6px 12px;
      .item-img {
        margin: 0 14px 2px 0;
      }
      .item-no {
        margin-right: 6px;
      }
      .item-value {
        font-size: 14px;
        color: #1162ba;
        line-height: 20px;
      }
    }
  }
  .net-top-in {
    .item-value {
      color: #188630 !important;
    }
  }
  .net-top-out {
    .item-value {
      color: #f39119 !important;
    }
  }

  // 使用率TOP
  #cpu-usage-top {
    .usage-top-container {
      height: 32px;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }

  .ant-table-thead > tr > th {
    padding: 8px;
    background: #fafafa !important;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
  .outPdf-thead th {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa !important;
  }

  // 导航
  .tool-box {
    position: fixed;
    bottom: 8vh;
    width: 120px;
    min-height: 100px;
    padding-bottom: 10px;
    padding-left: 12px;
    background: #ffffffee;
    border-radius: 6px 0 0 6px;
    transition: .3s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  }
}
</style>
