<template>
  <div :id="chartId"></div>
</template>

<script>
import { Chart, registerInteraction } from '@antv/g2'
import { getIntegerTicks } from '@/utils'
import { chartColors } from '@/utils/const'

const chart = {}
let activeElement = null

registerInteraction('line-view-element-filter', {
  showEnable: [
    {
      trigger: 'element:mouseenter',
      action: 'cursor:pointer'
    }
  ],
  start: [
    {
      trigger: 'element:click',
      action: context => {
        const { view, event } = context
        const lineView = view.parent.views[1]
        if (activeElement !== event.data?.data.name) {
          activeElement = event.data?.data.name
          lineView.filter('name', name => name === event.data?.data.name)
        } else {
          activeElement = null
          lineView.filter('name', null)
        }
        lineView.render(true)
      }
    }
  ]
})

export default {
  name: 'LinkageChart',
  props: {
    pieChartData: {
      type: Array,
      default: () => []
    },
    lineChartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    colors: {
      type: [Array, Function],
      default: () => chartColors
    },
    height: {
      type: Number,
      default: 240
    }
  },
  data () {
    return {
      view: {
        pie: null,
        line: null,
        text: null
      },
      total: 0
    }
  },
  computed: {
    // total () {
    //   let sum = 0
    //   if (this.pieChartData.length !== 0) {
    //     this.pieChartData.forEach(item => {
    //       sum += item.value
    //     })
    //   }
    //   return sum
    // }
  },
  mounted () {
    this.getTotal()
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].legend({
      position: 'bottom',
      itemValue: {
        formatter: (text, arr, index) => {
          return this.pieChartData[index].value
        }
      }
    })
    chart[this.chartId].tooltip({
      showMarkers: false,
      shared: true
    })
    this.view.pie = chart[this.chartId].createView({
      region: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: 0.32,
          y: 1
        }
      }
    })
    this.view.pie.coordinate('theta', {
      radius: 0.9,
      innerRadius: 0.65
    })
    this.view.pie.interaction('element-single-selected')
    this.view.pie.interaction('line-view-element-filter')
    this.view.pie
      .interval()
      .adjust('stack')
      .position('value')
      .color('name', this.colors)
      .style({
        fillOpacity: 1,
        lineWidth: 5,
        stroke: '#ffffff'
      })
      .state({
        selected: {
          style: element => {
            const shape = element.shape
            return {
              lineWidth: 1,
              stroke: shape.attr('fill'),
              strokeOpacity: shape.attr('fillOpacity')
            }
          }
        }
      })
    this.view.line = chart[this.chartId].createView({
      region: {
        start: {
          x: 0.32,
          y: 0
        },
        end: {
          x: 1,
          y: 1
        }
      }
    })
    this.view.line.scale('value', {
      min: 0,
      nice: true,
      tickMethod: getIntegerTicks
    })
    this.view.line.tooltip({
      showCrosshairs: true
    })
    this.view.line
      .area()
      .position('date*value')
      .color('name', this.colors)
      .shape('smooth')
    this.view.line
      .line()
      .position('date*value')
      .color('name', this.colors)
      .size(2.5)
      .shape('smooth')
    this.view.line
      .point()
      .shape('circle')
      .position('date*value')
      .size(3)
      .color('name', this.colors)

    this.view.text = this.view.pie.createView()
    this.updateAnnotation('总数', this.total)
    this.view.pie.on('element:statechange', e => {
      const { state, stateStatus, element } = e.gEvent.originalEvent
      if (state === 'selected') {
        const data = element.getData()
        if (stateStatus) {
          this.updateAnnotation(data.name, data.value)
        } else {
          this.updateAnnotation('总数', this.total)
        }
      }
    })
    chart[this.chartId].on('legend-item:click', (ev) => {
      const target = ev.target
      const { item, index } = target.get('delegateObject')
      const value = this.pieChartData[index].value
      if (item.unchecked) {
        this.total -= value
        this.updateAnnotation('总数', this.total)
      } else {
        this.total += value
        this.updateAnnotation('总数', this.total)
      }
    })
    this.view.pie.data(this.pieChartData)
    this.view.line.data(this.lineChartData)
    chart[this.chartId].render(true)
  },
  methods: {
    updateAnnotation (name, value) {
      this.view.text.annotation().clear(true)
      this.view.text
        .annotation()
        .text({
          position: ['50%', '48%'],
          content: name,
          style: {
            fontSize: 12,
            fill: '#8c8c8c',
            textAlign: 'center'
          },
          offsetY: -16
        })
        .text({
          position: ['50%', '56%'],
          content: value,
          style: {
            fontSize: 32,
            fill: '#8c8c8c',
            textAlign: 'center'
          }
        })
      this.view.text.render(true)
    },
    getTotal () {
      let sum = 0
      if (this.pieChartData.length !== 0) {
        this.pieChartData.forEach(item => {
          sum += item.value
        })
      }
      this.total = sum
    }
  },
  watch: {
    pieChartData (v, ov) {
      this.getTotal()
      this.view.pie.changeData(this.pieChartData)
      this.updateAnnotation('总数', this.total)
      chart[this.chartId].render(true)
    },
    lineChartData (v, ov) {
      this.view.line.changeData(this.lineChartData)
      chart[this.chartId].render(true)
    }
  }
}
</script>
