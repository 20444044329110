<template>
  <a-card :bodyStyle="{ padding: '18px 24px' }">
    <a-icon class="card-icon" :type="iconType" />
    <a-divider type="vertical" class="card-divider" />
    <a-statistic
      :value="value"
      :valueStyle="valueStyle"
      style="display: inline-block"
    >
      <div slot="title" style="color: rgba(0,0,0,.75)">{{title}}</div>
    </a-statistic>
  </a-card>
</template>

<script>
export default {
  name: 'CountCard',
  props: {
    value: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    iconType: {
      type: String
    }
  },
  computed: {
    valueStyle () {
      let color = '#5b5b5b'
      if (this.iconType === 'hdd') color = '#1a9349'
      else if (this.iconType === 'alert') color = '#ff4d4f'
      return {
        color,
        fontWeight: 600
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-icon {
  color: #0a6dd9;
  font-size: 24px;
  background: #0a6dd933;
  padding: 12px;
  border-radius: 50%;
  vertical-align: super;
}
.card-divider {
  margin: 0 24px;
  font-size: 48px;
  vertical-align: bottom;
  width: 1.5px;
  background: #f0f2f5;
}
</style>
